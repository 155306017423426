(function($) {
	
	var $root = document.documentElement,
		$toolbar = $('.accessibility-toolbar'),
		$accessibility_btn = $('.fa-universal-access'),
		$font_btn = $('.fa-font'),
		$size_btn = $('.fa-text-size'),
		$palette_btn = $('.fa-palette'),
		$styles = getComputedStyle($root),
		$html_tag = $('html');
	
	$toolbar.hide();
	
	$accessibility_btn.on('click', function() {
		$accessibility_btn.addClass('hovered');
		$toolbar.slideToggle(function() {
			if ( !$toolbar.is(':visible') ) {
				$accessibility_btn.removeClass('hovered');
			}
		});
		
	});
	
	$size_btn.on('click', function() {
		if ( $html_tag.css('font-size') == '34.375px' ){
			$html_tag.css('font-size', '22px');
		}
		else {
			$html_tag.css('font-size', ($html_tag.css('font-size').replace('px','')*1.25)+'px');
		}
	});
	
	$palette_btn.on('click', function() {
		if ( $html_tag.hasClass('colour-friendly-palette') ) {
			$('html').removeClass('colour-friendly-palette');
			$palette_btn.removeClass('hovered');
		}
		else {
			$('html').addClass('colour-friendly-palette');
			$palette_btn.addClass('hovered');
		}
	});
	
	$font_btn.data('--font-family', "OpenDyslexic, sans-serif");
	$font_btn.on('click', function() {
		$data = $styles.getPropertyValue('--font-family');
		$root.style.setProperty('--font-family', $font_btn.data('--font-family'));
		$font_btn.data('--font-family', $data);
		$font_btn.toggleClass('hovered');
	});
	
})(jQuery);